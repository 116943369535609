<template>
  <v-card>
    <v-card-title class="align-center">
      <v-avatar
        :color="color"
        size="38"
        class="elevation-3"
      >
        <v-icon
          size="24"
          color="white"
          class="rounded-0"
        >
          {{ icon }}
        </v-icon>
      </v-avatar>
      <div class="font-weight-semibold text-sm align-self-center ma-0 ml-2 text--h6">
        {{ statTitle }}
      </div>
      <v-spacer></v-spacer>
      <v-btn v-if="showBreakdownBtn && hasStatistics" color="primary" @click="openReport">Report</v-btn>
    </v-card-title>

    <v-card-text class="text-no-wrap text--primary card-pie-min-height d-flex justify-center">
      <span class="font-weight-semibold text-h2 me-1">{{ statistics }}</span>
      <!-- <span
        class="percentage text-h6 mb-2"
        :class="checkChange(change) ? 'success--text':'error--text'"
      > {{ change }}</span> -->
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    statTitle: { type: String, default: '' },
    icon: { type: String, default: '' },
    color: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    statistics: { type: String, default: '' },
    change: { type: String, default: '' },
    breakdownDialog: { type: Boolean, default: false },
    showBreakdownBtn: { type: Boolean, default: true },
  },

  data: () => ({
    selectedDate: {text: '24 Hours', value: '24'},
    items: [
      {text: '24 Hours', value: '24'},
      {text: '7 Days', value: '7'},
      {text: '30 Days', value: '30'},
      {text: '60 Days', value: '60'},
      {text: '90 Days', value: '90'},
      {text: '365 Days', value: '365'},
      {text: 'Month To Date', value: 'Month'},
      {text: 'Quarter To Date', value: 'Quarter'},
      {text: 'Year To Date', value: 'Year'},
    ],
  }),

  setup() {
    const checkChange = value => {
      const firstChar = value.charAt(0)
      if (firstChar === '+') {
        return true
      }

      return false
    }

    return {
      checkChange,
    }
  },

  computed: {
    hasStatistics() {
      if (parseInt(this.statistics)) return true
      return false
    }
  },

  methods: {
    openReport () {
      this.$emit('update:breakdownDialog', true)
    }
  },
}
</script>
