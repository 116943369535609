<template>
  <v-card>
    <v-card-title class="align-start">
      <v-avatar
        :color="color"
        size="38"
        class="elevation-3"
      >
        <v-icon
          size="24"
          color="white"
          class="rounded-0"
        >
          {{ icon }}
        </v-icon>
      </v-avatar>
      <div class="font-weight-semibold text-sm align-self-center ma-0 ml-2 text--h6">
        {{ statTitle }}
      </div>
    </v-card-title>

    <v-card-text class="text-no-wrap text--primary card-height">
      <v-row no-gutters>
        <v-col
          v-if="avg"
          sm="2"
          align-self="center"
        >
          <h1 class="text-center">{{ avg }}</h1>
        </v-col>
        <v-col :sm="avg ? '10' : ''">
          <vue-apex-charts
            class="custom-apex-chart"
            :options="options"
            :series="series"
            height="300"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
  },

  props: {
    statTitle: { type: String, default: '' },
    icon: { type: String, default: '' },
    color: { type: String, default: '' },
    total: { type: String, default: '' },
    avg: { type: String, default: '' },

    series: { type: Array, default() { return [] } },
    options: { type: Object, default() { return {} } },
  },

  data: () => ({

  }),

  setup() {
    const checkChange = value => {
      const firstChar = value.charAt(0)
      if (firstChar === '+') {
        return true
      }

      return false
    }

    return {
      checkChange,
    }
  },
}
</script>
