<template>
  <v-card>
    <v-card-title class="align-start">
      <v-avatar
        :color="color"
        size="38"
        class="elevation-3"
      >
        <v-icon
          size="24"
          color="white"
          class="rounded-0"
        >
          {{ icon }}
        </v-icon>
      </v-avatar>

      <v-spacer />

      <v-col
        align-self="center"
        class="pa-0"
        cols="12"
        sm="3"
      >
        <p class="text-xs text--secondary text-right mb-0">
          ({{ total }} total)
        </p>
      </v-col>
    </v-card-title>

    <v-card-text class="text-no-wrap text--primary card-pie-min-height">
      <p class="font-weight-semibold text-sm align-self-center ma-0 ml-2">
        {{ statTitle }}
      </p>

      <vue-apex-charts
        class="custom-apex-chart"
        :options="options"
        :series="series"
        height="80"
        type="donut"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
  },

  props: {
    statTitle: { type: String, default: '' },
    icon: { type: String, default: '' },
    color: { type: String, default: '' },
    total: { type: String, default: '0' },
    series: { type: Array, default() { return [] } },
    options: { type: Object, default() { return {} } },
  },

  data: () => ({

  }),

  setup() {
    const checkChange = value => {
      const firstChar = value.charAt(0)
      if (firstChar === '+') {
        return true
      }

      return false
    }

    return {
      checkChange,
    }
  },
}
</script>
