<template>
  <v-row>
    <v-col sm="12">
      <v-row class="justify-end">
        <v-col sm="auto">
          <v-select
            v-model="selectedDate"
            :items="dateRange"
            hide-details
            outlined
            dense
            solo
            class="elevation-3"
            @change="fetchData"
          />
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" md="6" lg="4">
      <statistics-card-vertical
        :change="totalNewUsers.change"
        :color="totalNewUsers.color"
        :icon="totalNewUsers.icon"
        :statistics="totalNewUsers.statistics"
        :stat-title="totalNewUsers.statTitle"
        :subtitle="totalNewUsers.subtitle"
        :breakdown-dialog.sync="user_dialog"
      />
    </v-col>

    <v-dialog v-model="user_dialog" width="500">
      <v-card>
        <v-card-title class="text-h5">
          New Users Breakdown
          <v-btn class="ml-auto" icon color="error" @click="user_dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-container class="pa-5">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Name</th>
                  <th class="text-left">Company</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(u, i) in newUsers" :key="i">
                  <td>
                    <router-link :to="{ name: 'accounts-show', params: { id: u.id } }">
                      {{ u.name }}
                    </router-link>
                  </td>
                  <td>{{ u.company }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-container>
      </v-card>
    </v-dialog>

    <v-col cols="12" md="6" lg="4">
      <statistics-card-vertical
        :change="totalNewLabels.change"
        :color="totalNewLabels.color"
        :icon="totalNewLabels.icon"
        :statistics="totalNewLabels.statistics"
        :stat-title="totalNewLabels.statTitle"
        :subtitle="totalNewLabels.subtitle"
        :breakdown-dialog.sync="label_dialog"
      />
    </v-col>

    <v-dialog v-model="label_dialog" width="800">
      <v-card>
        <v-card-title class="text-h5">
          New Labels Breakdown
          <v-btn class="ml-auto" icon color="error" @click="label_dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-container class="pa-5">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Name</th>
                  <th class="text-left">Company</th>
                  <th class="text-center">Labels</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(l, i) in newLabels" :key="i">
                  <td>
                    <router-link :to="{ name: 'accounts-show', params: { id: l.id } }">
                      {{ l.name }}
                    </router-link>
                  </td>
                  <td>{{ l.company }}</td>
                  <td class="text-right">{{ l.labels }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-container>
      </v-card>
    </v-dialog>

    <v-col cols="12" md="6" lg="4">
      <statistics-card-pie-chart
        :color="totalOrderByMarketplace.color"
        :icon="totalOrderByMarketplace.icon"
        :stat-title="totalOrderByMarketplace.statTitle"
        :total="totalOrderByMarketplace.total"
        :series="totalOrderByMarketplace.series"
        :options="totalOrderByMarketplace.options"
      />
    </v-col>

    <v-col cols="12" md="6" lg="4">
      <statistics-card-line-chart
        :color="weeklyPerformanceChart.color"
        :icon="weeklyPerformanceChart.icon"
        :stat-title="weeklyPerformanceChart.statTitle"
        :series="weeklyPerformanceChart.series"
        :options="weeklyPerformanceChart.options"
      />
    </v-col>

    <v-col cols="12" md="6" lg="4">
      <statistics-card-line-chart
        :color="activeShippingCustomers.color"
        :icon="activeShippingCustomers.icon"
        :stat-title="activeShippingCustomers.statTitle"
        :avg="activeShippingCustomers.avg"
        :series="activeShippingCustomers.series"
        :options="activeShippingCustomers.options"
      />
    </v-col>

    <v-col cols="12" md="6" lg="4">
      <statistics-card-line-chart
        :color="qualifiedChart.color"
        :icon="qualifiedChart.icon"
        :stat-title="qualifiedChart.statTitle"
        :series="qualifiedChart.series"
        :options="qualifiedChart.options"
      />
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import StatisticsCardVertical from '@/components/statistics-card/StatisticsCardVertical.vue'
import StatisticsCardPieChart from '@/components/statistics-card/StatisticsCardPieChart.vue'
import StatisticsCardLineChart from '@/components/statistics-card/StatisticsCardLineChart.vue'

import moment from 'moment'
import axios from 'axios'
import _ from 'lodash'

const defaultPieOptions = {
  dataLabels: {
    enabled: false,
  },
  legend: {
    position: 'left',
    width: 225,
    offsetY: -15,
    fontSize: '10px',
    fontWeight: 300,
    formatter: (seriesName, opts) => [seriesName, ' - ', opts.w.globals.series[opts.seriesIndex], '%'],
  },
  plotOptions: {
    pie: {
      offsetX: 15,
    },
  },
}

const defaultLineOptions = {
  chart: {
    height: 300,
    zoom: {
      enabled: false,
    },
  },
  markers: {
    size: 6,
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'straight',
    width: 2,
  },
  grid: {
    row: {
      colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
      opacity: 0.5,
    },
  },
}

export default {
  components: {
    StatisticsCardVertical,
    StatisticsCardPieChart,
    StatisticsCardLineChart,
  },

  data: () => ({
    user_dialog: false,
    newUsers: [],
    label_dialog: false,
    newLabels: [],
    selectedDate: '1',
    dateRange: [
      { text: '24 Hours', value: '1' },
      { text: '7 Days', value: '7' },
      { text: '30 Days', value: '30' },
      { text: '60 Days', value: '60' },
      { text: '90 Days', value: '90' },
      { text: '365 Days', value: '365' },
      // {text: 'Month To Date', value: 'Month'},
      // {text: 'Quarter To Date', value: 'Quarter'},
      // {text: 'Year To Date', value: 'Year'},
    ],
    totalNewUsers: {
      statTitle: 'New Users Sign Ups',
      icon: 'mdi-account-multiple',
      color: 'primary',
      statistics: '',
      change: '',
    },
    totalNewLabels: {
      statTitle: 'New Labels',
      icon: 'mdi-file-document-multiple',
      color: 'success',
      statistics: '',
      change: '',
    },
    totalOrderByMarketplace: {
      statTitle: 'Orders by Marketplace - 30 days',
      icon: 'mdi-shopping',
      color: 'secondary',
      total: '325',
      series: [],
      options: {
        labels: [],
        ...defaultPieOptions,
      },
    },
    weeklyPerformanceChart: {
      statTitle: 'Weekly Performance Chart',
      icon: 'mdi-chart-line',
      color: 'primary',
      series: [],
      options: {
        ...defaultLineOptions,
        title: {
          text: 'Weekly Performance Chart',
          align: 'left',
        },
        xaxis: {
          categories: [],
        },
      },
    },
    activeShippingCustomers: {
      statTitle: 'Active Shipping Customers',
      icon: 'mdi-briefcase-account',
      color: 'warning',
      avg: '0',
      series: [],
      options: {
        ...defaultLineOptions,
        title: {
          text: 'Shipping Customers',
          align: 'left',
        },
        xaxis: {
          categories: [],
        },
      },
    },
    qualifiedChart: {
      statTitle: 'Qualified Chart',
      icon: 'mdi-chart-bar',
      color: 'primary',
      series: [],
      options: {
        chart: {
          foreColor: '#ccc',
          type: 'bar',
          // height: 350,
          // stacked: true,
          // stackType: '100%',
        },
        plotOptions: {
          bar: {
            // horizontal: true,
            // columnWidth: '70%',
            // barHeight: '20%',
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
        },
        colors: ['#70ad47', '#fec134'],
        xaxis: {
          categories: ['Qualified', 'Not Qualified'],
          labels: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        tooltip: {
          x: {
            show: false,
          },
        },
      },
    },
  }),

  async mounted() {
    document.title = 'Dashboard';

    this.$root.$on('updateData', tz => {
      this.fetchData(tz)
    })

    await this.fetchData()
  },

  methods: {
    async fetchData(tz = '') {
      if (this.$root.progressbar) {
        this.$root.progressbar.show()
      }
      const token = this.$store.state.token
      const url = process.env.VUE_APP_API_URL
      const days = this.selectedDate
      const timezone = tz || JSON.parse(localStorage.getItem('tz', 'UTC'))
      const now = moment.tz(timezone).startOf('day').toISOString()
      const nowEnd = moment.tz(timezone).endOf('day').toISOString()
      // console.log(now);

      const { data } = await axios.get(`${url}/api`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { day: days, now, nowEnd },
      })

      const {
        newUsers,
        newLabels,
        perMarketPlace,
        weeklyPerformance,
        perShippingServices,
        weeklyActiveUsers,
        qualify,
      } = data

      // --------------------- New Users and Labels ---------------------

      this.totalNewUsers.statistics = newUsers[0].new_users.toString()
      this.totalNewLabels.statistics = newLabels[0].new_labels.toString()

      // --------------------- MarketPlace ---------------------

      const marketPlaceSeries = perMarketPlace.map(m => m.labels)
      const marketPlaceLabels = perMarketPlace.map(m => m.name)

      const date = this.dateRange.find(d => d.value === this.selectedDate)
      this.totalOrderByMarketplace.statTitle = `Orders by Marketplace - ${date.text}`
      this.totalOrderByMarketplace.series = marketPlaceSeries
      ;(this.totalOrderByMarketplace.options = {
        labels: marketPlaceLabels,
        ...defaultPieOptions,
      }),
        (this.totalOrderByMarketplace.total = marketPlaceSeries.reduce((a, b) => a + b, 0).toString())

      // --------------------- Weekly Performance ---------------------

      const weeklyPerformanceChartSeries = weeklyPerformance.map(p => p.labels)
      const weeklyPerformanceChartCategories = weeklyPerformance.map(p => {
        let start = moment().week(p.week).startOf('week').format('MM/DD/YY')
        let end = moment().week(p.week).endOf('week').format('MM/DD/YY')
        return [start, end]
      })
      this.weeklyPerformanceChart.series = [
        {
          type: 'line',
          name: 'Customers',
          data: weeklyPerformanceChartSeries,
        },
      ]
      this.weeklyPerformanceChart.options = {
        ...defaultLineOptions,
        title: {
          text: 'Weekly Performance Chart',
          align: 'left',
        },
        xaxis: {
          categories: weeklyPerformanceChartCategories,
        },
      }

      // --------------------- Active Shipping Customers ---------------------

      const activeShippingCustomersSeries = weeklyActiveUsers.map(u => u.users)
      const activeShippingCustomersCategories = weeklyActiveUsers.map(u => {
        let start = moment().week(u.week).startOf('week').format('MM/DD/YY')
        let end = moment().week(u.week).endOf('week').format('MM/DD/YY')
        return [start, end]
      })

      this.activeShippingCustomers.series = [
        {
          type: 'line',
          name: 'Customers',
          data: activeShippingCustomersSeries,
        },
      ]
      this.activeShippingCustomers.options = {
        ...defaultLineOptions,
        title: {
          text: 'Shipping Customers',
          align: 'left',
        },
        xaxis: {
          categories: activeShippingCustomersCategories,
        },
      }
      this.activeShippingCustomers.avg = parseInt(
        activeShippingCustomersSeries.reduce((a, b) => a + b, 0) / activeShippingCustomersSeries.length,
      ).toString()

      // --------------------- Qualified ---------------------

      const totalQualified = qualify.find(q => q.Qualified === 'Q')
      const totalNotQualified = qualify.find(q => q.Qualified === 'NQ')

      const q = qualify.map(q => q.labels).reduce((a, b) => a + b, 0)

      this.qualifiedChart.options = {
        dataLabels: {
          formatter: function (val, opts) {
            return ((parseInt(val) / q) * 100).toFixed(2) + '%'
          },
        },
        chart: {
          foreColor: '#ccc',
          type: 'bar',
          // height: 300,
          // stacked: true,
          // stackType: '100%',
        },
        plotOptions: {
          bar: {
            // horizontal: true,
            // columnWidth: '70%',
            // barHeight: '20%',
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
        },
        colors: ['#70ad47', '#fec134'],
        xaxis: {
          categories: ['Qualified', 'Not Qualified'],
          labels: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        tooltip: {
          x: {
            show: false,
          },
        },
      }

      this.qualifiedChart.series = [
        {
          name: 'Qualified',
          data: [totalQualified.labels],
        },
        {
          name: 'Not Qualified',
          data: [totalNotQualified.labels],
        },
      ]

      // --------------------- User and Label Breakdown ---------------------

      const { data: userReport } = await axios.get(`${url}/api/report/new-users`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { day: days, now: nowEnd },
      })

      this.newUsers = userReport

      const { data: labelReport } = await axios.get(`${url}/api/report/new-labels`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { day: days, now: nowEnd },
      })

      this.newLabels = _.orderBy(labelReport, ['labels'], 'desc')
      if (this.$root.progressbar) {
        this.$root.progressbar.hide()
      }
    },
  },
}
</script>
